import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common/common.service';

import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { TaskListService } from './task-list.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {

  @Input() system: string = '';
  @Output() openTaskEvent: EventEmitter<any> = new EventEmitter<any>();

  taskList: Array<any> = [];

  constructor(
    private taskListService: TaskListService,
    private commonService: CommonService,
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService) {
  }

  async ngOnInit(): Promise<void> {

    // hiện spinner process
    this.loadingSpinnerService.show();

    // lấy danh sách task
    const taskListInfo = await this.getTaskList();
    this.taskList = taskListInfo.taskList;
    
    // ẩn spinner process
    this.loadingSpinnerService.hide();

  }

  onOpenTask(task: any) {
    if (task?.taskId === 'trongcay') {
      this.openTaskEvent.emit(task);
    } else {
      alert('Đang phát triển - Vui lòng quay lại sau !!!');
    }
  }

  onBuySellCoin() {
    alert('Đang phát triển - Vui lòng quay lại sau !!!');
  }

  onBuySellUsdt() {
    alert('Đang phát triển - Vui lòng quay lại sau !!!');
  }

  onPlayChanle() {
    alert('Đang phát triển - Vui lòng quay lại sau !!!');
  }

  onPlayLoto() {
    alert('Đang phát triển - Vui lòng quay lại sau !!!');
  }

  getTaskList(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        system: this.system
      };
      this.commonService.exeApi('system/task/list', body).subscribe(kekkaInfo => {
        resolve(kekkaInfo);
       }, error => {
          reject(error);
       });
    });
  }





  

  


}
