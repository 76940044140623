import {Component, Input, OnInit} from '@angular/core';
import { SelectImageService } from './select-image.service';
import * as uuid from 'uuid';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonLogicService } from 'src/app/service/common/common-logic.service';
import { CommonService } from 'src/app/service/common/common.service';

@Component({
  selector: 'app-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.css']
})
export class SelectImageComponent implements OnInit {

  @Input() UUID: string = uuid.v4();
  @Input() taskId: string = '';

  // xác định hệ thống
  system = '1';
  gameName = 'trongcay';
  gameTitle = '';
  huongdan: string = '';

  gameKekka: string = '';


  showPlaySelectImageList: Array<Array<any>> =  [];
  imageCouple: number = 0;
  playGameOnS: number = 0;
  recordImage: number = 6;

  gameId = '';
  isStartGame: boolean = true;
  isPlaying: boolean = false;
  isEndgame: boolean = false;
  playedCount = 0;
  playedCouple: Array<string> = [];
  playedList: Array<string> = [];
  playedInfo = '0 / 0';

  coin:number = 0;

  //quảng cáo chuyển đổi gồm pc-mobile
  fixAd = '3143b038272a6baeecbd83fb751ca007';
  popAd1 = 'fc6a335f2a154930034c836e59ab1565';
  popAd2 = '2a2124c8db56740bde10c00bd2857190';

  constructor(
    private selectImageService: SelectImageService,
    private commonLogicService: CommonLogicService,
    private commonService: CommonService,
    private router: Router,
    private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {

    if (this.taskId === 'trongcay') {
      this.gameTitle = 'Trồng Cây';
    }

    this.gameName = this.taskId;

    // mới vào thì ẩn đi button bắt đầu
    this.isStartGame = false;
    this.startGame();

    // lấy thông tin user (số Xu) từ server tại đây
    const userInfo = await this.commonLogicService.getUserInfo(this.system, this.UUID);
    if (userInfo.exist === '1') {
      this.coin = userInfo.coin;
    } else {
      this.router.navigate(['login', this.system, '']);
    }

    setTimeout(() => {
      this.isStartGame = true;
    }, 7*1000)
  }

  async onStartGame() {

    // clear data game
    this.clearGame();

    // get data và hiển thị ván game mới
    this.startGame();

    // ẩn đi các phần giới thiệu và button start
    this.isStartGame = false;

    // bật cờ bắt đầu chơi game
    this.isPlaying = true;

  }

  onImageClick(playId: string, rIndex: number, iIndex: number) {
    if (this.playedCouple.length < 2) {
      this.playedCouple.push(playId);
    }

    if (this.playedCouple.length >= 2) {
      this.playedList.push(this.playedCouple.sort().join());
      this.playedCouple = [];
    }

    this.showPlaySelectImageList[rIndex][iIndex].show = false;
    this.playedCount = this.playedList.length;

    // nếu đã click hết image thì kết thúc game
    if (this.playedCount === this.imageCouple) {
      this.endGame();
    }
    
  }

  async startGame() {
    const tmpSelectImageList = await this.getSelectImageList();

    const playSelectImageList: Array<any> = tmpSelectImageList.playSelectImageList || [];
    this.imageCouple = tmpSelectImageList.imageCouple || 0;
    this.playGameOnS = tmpSelectImageList.playGameOnS || 0;
    this.gameId = tmpSelectImageList.gameId;
    
    let recordImageList: Array<any> = []
    for (const imageInfo of playSelectImageList) {
      imageInfo.src = imageInfo.imageLink || imageInfo.base64 || '';
      imageInfo.show = true;
      recordImageList.push(imageInfo);
      if (recordImageList.length >= this.recordImage) {
        this.showPlaySelectImageList.push(recordImageList);
        recordImageList = [];
      }
    }

  }

  async clearGame() {
    this.showPlaySelectImageList = [];
    this.playedCouple = [];
    this.playedList = [];
  }

  async endGame() {

    // lấy kết quả game từ server
    const kekkaInfo = await this.checkKekkaGame();

    // di chuyển đến trang thông tin kết quả để hiển thị qc
    const urlPart = ['endgame', this.popAd1, this.popAd2, kekkaInfo.kekka || '0', this.UUID, 
    this.gameId, this.system, this.gameName].join('/');
    // lưu ý theo đặc tính của angular là chỉ 1 page và ko reload lại page dù có di chuyển trang
    // vì ko reload page nên qc ko đc hiển thị --> dùng phương pháp di chuyển truyền thống để page 
    // đc load lại ---> qc đc hiển thị
    document.location.href = './' + urlPart;
  
  }

  checkKekkaGame(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        UUID: this.UUID,
        gameId: this.gameId,
        system: this.system,
        gameName: this.gameName,
        playedListAsString: this.playedList.sort().join()
      };
      this.commonService.exeApi('game/checkKekka/vanGame', body).subscribe(kekkaInfo => {
        resolve(kekkaInfo);
       }, error => {
          reject(error);
       });
    });
  }

  getSelectImageList(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        UUID: this.UUID,
        system: this.system,
        gameName: this.gameName
      };
      this.commonService.exeApi('game/get/vanGame', body).subscribe(selectImageList => {
        resolve(selectImageList);
       }, error => {
          reject(error);
       });
    });
  }


}
