<!-- footer -->
<div class="mt-5 ml-2 mr-2">
   <div class="d-flex" style="height: 15px;">
      <div class="d-flex" style="font-size: 6pt; font-weight: bold; color: chocolate;">
         Copyright © SaishinDev 2020 - {{copyWright}}
      </div>
      <div class="d-flex flex-grow-1 flex-row-reverse" style="font-size: 6pt; font-weight: bold; color: chocolate;">
         <div class="d-flex">
            <a class="mr-2" style="color: chocolate;" href="">Privacy Policy</a>
            <p>|</p>
            <a style="color: chocolate;" class="ml-2" href=""> Terms of Service</a>
         </div>
      </div>
   </div>
</div>