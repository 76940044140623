import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import * as uuid from 'uuid';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { EndgameDialogComponent } from '../endgame-dialog/endgame-dialog.component';
import { Router } from '@angular/router';
import { getCookie, setCookie, uniqueId, getUUID, setUUID, getSocialUser, getSystemName } from '../../service/common/common-function.service';

import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from 'angularx-social-login';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { CommonService } from 'src/app/service/common/common.service';
import { CommonLogicService } from 'src/app/service/common/common-logic.service';

@Component({
  selector: 'app-history-make-coin',
  templateUrl: './history-make-coin.component.html',
  styleUrls: ['./history-make-coin.component.css']
})
export class HistoryMakeCoinComponent implements OnInit {

  @Input() system: string = '';
  @Input() userId: string = '';

  @Output() onBackSystemEvent: EventEmitter<string> = new EventEmitter<string>();

  title: string = 'Lịch Sử Cày Xu';

  skip: number = 0;
  limit: number = 35;

  historyList: Array<any> = [];
  historyCount: number = 0;

  constructor(
    private commonLogicService: CommonLogicService,
    private commonService: CommonService,
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService,
    private socialAuthService: SocialAuthService) {
  }

  async ngOnInit(): Promise<void> {

    // hiển thị spinner process
    this.loadingSpinnerService.show();

    // lấy tổng số record bảng history tương ứng của user
    const historyCountInfo = await this.getHistoryCount();
    this.historyCount = historyCountInfo.count;

    // lấy lịch sử cày xu theo điều kiện
    this.historyList = await this.getHistoryMakeCoin(this.skip, this.limit);
  
    // ẩn spinner process
    this.loadingSpinnerService.hide();

  }

  // get lịch sử cày xu từ trên xuống (mới nhất hiện trước)
  getHistoryMakeCoin(skip: number, limit: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        system: this.system,
        userId: this.userId,
        skip: skip,
        limit: limit
      };
      this.commonService.exeApi('system/user/history/make/coin', body).subscribe(kekkaInfo => {
        resolve(kekkaInfo);
       }, error => {
          reject(error);
       });
    });
  }

  // get tổng số record của bảng history
  getHistoryCount(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        system: this.system,
        userId: this.userId
      };
      this.commonService.exeApi('system/user/history/make/coin/count', body).subscribe(kekkaInfo => {
        resolve(kekkaInfo);
       }, error => {
          reject(error);
       });
    });
  }

  onBack() {
    this.onBackSystemEvent.emit('system');
  }

  async onFirst() {
    this.skip = 0;
    // lấy lịch sử cày xu theo điều kiện
    this.historyList = await this.getHistoryMakeCoin(this.skip, this.limit);
  }

  async onPrevious() {
    this.skip = this.skip > this.limit ? (this.skip - this.limit) : 0;
    // lấy lịch sử cày xu theo điều kiện
    this.historyList = await this.getHistoryMakeCoin(this.skip, this.limit);
  }

  async onNext() {
    // nếu còn data thì mới lấy tiếp
    if ((this.skip + this.limit) < this.historyCount) {
      this.skip = this.skip + this.limit;
      // lấy lịch sử cày xu theo điều kiện
      this.historyList = await this.getHistoryMakeCoin(this.skip, this.limit);
    }
  }

  async onLast() {
    const mod = this.historyCount % this.limit;
    const lastPage = mod === 0 ? (this.historyCount / this.limit) : (((this.historyCount - mod) / this.limit) + 1);
    this.skip = lastPage > 1 ? ((lastPage - 1) * this.limit) : 0;
    // lấy lịch sử cày xu theo điều kiện
    this.historyList = await this.getHistoryMakeCoin(this.skip, this.limit);
  }

}
