<div class="row">
    <div class="col-12 text-center">
        <span style="font-size: 15pt;">{{title}}</span>
    </div>
</div>
<div class="row">
    <div class="col-12 text-center">
        <app-translate></app-translate>
    </div>
</div>

