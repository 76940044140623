<!-- contact social -->
<div class="d-flex flex-row-reverse align-items-center flex-grow-1 mr-2" style="margin-bottom: 5px;  padding: 0px;">
   <div matTooltip="Xem Facebook nhà phát triển !" class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px;">
      <img alt="img" (click)="openLink('https://www.facebook.com/profile.php?id=100078797647368')" 
      src="../../../assets/image/facebook_chokolet.png" class="ml-2" style="width: 18px; height: 18px;"/>
   </div>
   <div matTooltip="Xem hướng dẫn kiếm tiền trên Youtube !" class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px;">
      <img alt="img" (click)="openLink('https://www.youtube.com/channel/UCc8sICVZ4KLWQOVodM-cQXQ')" 
      src="../../../assets/image/youtube_chokolet.png" class="ml-2" style="width: 18px; height: 18px;"/>
   </div>
   <div matTooltip="Email Admin: saishindev@gmail.com !" class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px;">
      <img alt="img" (click)="showAlert('Email Admin: saishindev@gmail.com !')" 
      src="../../../assets/image/gmail_chokolet.png" class="ml-2" style="width: 18px; height: 18px;"/>
   </div>
   <div matTooltip="Nhóm cày xu kiếm tiền Telegram !" class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px;">
      <img alt="img" (click)="openLink('https://t.me/cayxukiemtien')" 
      src="../../../assets/image/telegram_chokolet .png" class="ml-2" style="width: 18px; height: 18px;"/>
   </div>
   <div matTooltip="Nhóm cày xu kiếm tiền Zalo !" class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px;">
      <img alt="img" (click)="openLink('https://zalo.me/g/xnniht526')" 
      src="../../../assets/image/zalo_chokolet.png" class="ml-2" style="width: 18px; height: 18px;"/>
   </div>
   <div matTooltip="Nhóm cày xu kiếm tiền Messenger !" class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px;">
      <img alt="img" (click)="openLink('https://www.facebook.com/messages/t/100078797647368')" 
      src="../../../assets/image/messenger_chokolet.png" class="ml-2" style="width: 18px; height: 18px;"/>
   </div>
</div>