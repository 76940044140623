import {AfterViewInit, Component, Inject, Input, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getRandomInt } from '../../service/common/common-function.service';
import { SelectImageService } from '../../component/select-image/select-image.service';
import moment = require('moment');
import { CommonService } from 'src/app/service/common/common.service';
import { CommonLogicService } from 'src/app/service/common/common-logic.service';

@Component({
  selector: 'app-endgame-page',
  templateUrl: './endgame-page.component.html',
  styleUrls: ['./endgame-page.component.css']
})
export class EndgamePageComponent implements OnInit, AfterViewInit {

  gameKekka: string = '';
  ad1: string = '';
  ad2: string = '';
  userId: string = '';
  gameId: string = '';
  system: string = '';
  gameName: string = '';

  showCloseButton: string = '';
  gameInfo: string = '';
  adTagList: Array<HTMLElement | any> = [];

  constructor(private route: ActivatedRoute, private router: Router,
    private commonService: CommonService,
    private commonLogicService: CommonLogicService) {
    this.gameKekka = this.route.snapshot.params.gameKekka;
    this.ad1 = this.route.snapshot.params.ad1;
    this.ad2 = this.route.snapshot.params.ad2;
    this.userId = this.route.snapshot.params.userId;
    this.gameId = this.route.snapshot.params.gameId;
    this.system = this.route.snapshot.params.system;
    this.gameName = this.route.snapshot.params.gameName;
  }

  async ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // 5 giây sau khi load, thì hiển thị nút đóng
      const r = getRandomInt(1, 4);
      this.showCloseButton = r.toString();

      this.gameInfo = this.gameKekka === '1' ? 'Win !!!' : 'Fail !!!';
    }, 7*1000)
  }

  async onCloseAd() {

    // // get thẻ quảng cáo, tạm thơi check tại client, sau này nghiên cứu sẽ check trên server
    // const ad1 = document.getElementById('adEl1');
    // const ad2 = document.getElementById('adEl2');
    // this.adTagList = [];
    // this.adTagList = [ad1, ad2];
    // // const checkAd = this.commonLogicService.checkAd(this.adTagList);
    // const checkAd = this.commonLogicService.checkPopUpAd();

    // // cộng xu
    // // tiến hành cộng xu (chạy bất đồng bộ)
    // if (this.gameKekka === '1') {
    //   const kekka = await this.rewardXuShori();
    //   console.log(kekka);
    // }
    
    const urlPart = ['selectImage', this.userId, this.gameName].join('/');
    document.location.href = './' + urlPart;

  }

  rewardXuShori(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        UUID: this.userId,
        gameId: this.gameId,
        system: this.system,
        gameName: this.gameName,
        clientDate: moment(new Date()).format('YYYYMMDDhhmmss')
      };
      this.commonService.exeApi('game/rewardXu/vanGame', body).subscribe(req => {
        resolve(req);
       }, error => {
          reject(error);
       });
    });
  }

}

