<div class="container-fluid" style="padding: 0px; height: 100vh;">

   <!-- header -->
   <div class="d-flex pt-2 pb-1 shadow">
      <div class="d-flex flex-grow-1 ml-2">
         <div class="ml-1">
            <div class="d-flex">
               <img alt="img" (click)="openLink('./')"
               src="../../../assets/image/saishin-dev.png" style="width: 56px; height: 56px; cursor: pointer;"/>
            </div>
            <div style="color:chocolate; font-weight: bold; font-size: 8pt; margin-top: 6px;">Saishin Dev</div>
         </div>
         <div class="ml-3 d-flex align-items-center flex-grow-1">
            <div class="d-flex flex-column flex-grow-1">
               
               <!-- contact social -->
               <app-contact-social></app-contact-social>
               
               <!-- title -->
               <div class="flex-grow-1" style="font-weight:bold; font-size: 15pt; color:chocolate;">Trung Tâm Kiếm Tiền Online</div>
               
               <!-- menu -->
               <div class="d-flex flex-row-reverse align-items-center flex-grow-1 mr-2" style="margin-top: 5px; padding: 0px;">
                  <div class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                     <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                        <div (click)="menuBarClick()" class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                           <div matTooltip="Thông tin về Công ty - Nhà phát triển !">Công Ty</div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                     <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                        <div (click)="menuBarClick()" class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                           <div matTooltip="Bài viết hướng dẫn, kĩ thuật kiếm tiền !">Bài Viết</div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                     <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                        <div (click)="menuBarClick()" class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                           <div matTooltip="Tin nóng, tin đổi thưởng !">Tin Nóng</div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                     <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                        <div (click)="openLink('./')" class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                           <div matTooltip="Về trang chủ !">Home</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- body -->
   <div *ngIf="isHome">

      <!-- Sự Kiện -->
      <div class="d-flex">
         <div class="d-flex card shadow-sm ml-1 mt-3" style="background-color: chocolate; color: white; font-weight: bold; font-size: 10pt; width: 238px;">
            <div class="card-body" style="padding: 2px; padding-left: 6px;">
               <div>Sự Kiện Hót Hôm Nay: {{eventDate}}</div>
            </div>
         </div>
      </div>
      <div class="d-flex">
         <div class="d-flex flex-wrap align-items-center mt-2">
            <div class="d-flex align-items-center flex-fill card shadow-sm ml-2 mr-2 mt-1">
               <div class="card-body" style="padding: 2px;">
                  <div class="d-flex p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 10pt;">
                        <div *ngFor="let eventHot of eventHotList; index as index" class="d-flex">
                           <div>{{eventHot}}</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      
      <!-- các hệ thống -->
      <div class="d-flex card shadow-sm ml-1 mr-1 mt-3" style="background-color: chocolate; color: white; font-weight: bold; font-size: 10pt; width: 238px;">
         <div class="card-body" style="padding: 2px; padding-left: 6px;">
            <div>Danh Sách Hệ Thống Kiếm Tiền</div>
         </div>
      </div>

      <div class="d-flex flex-wrap align-items-center mt-2">

         <!-- lam vuon -->
         <div class="d-flex align-items-center flex-fill" style="cursor: pointer;" (click)="onSystem('1')">
            <div class="d-flex align-items-center flex-fill card shadow-sm ml-1 mr-1 mt-1">
               <div class="card-body" style="padding: 2px;">
                  <div class="d-flex justify-content-center align-items-center p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Làm Vườn - Nông Dân Kiếm Tiền</div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                     <div><img alt="img" src="../../../assets/image/lamvuon_icon.png" style="width: 200px; height: 200px;" /></div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 10pt;">Số 1 kiếm tiền -> Chạm Vào Ngay !</div>
                  </div>
               </div>
            </div>
         </div>
         
         <!-- coin84 -->
         <div class="d-flex align-items-center flex-fill" style="cursor: pointer;" (click)="onSystem('5')">
            <div class="d-flex align-items-center flex-fill card shadow-sm ml-1 mr-1 mt-1">
               <div class="card-body" style="padding: 2px;">
                  <div class="d-flex justify-content-center align-items-center p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Coin84 - Đào Coin Đổi Tiền</div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                     <div><img alt="img" src="../../../assets/image/coin84_icon.png" style="width: 200px; height: 200px;" /></div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 10pt;">Tiên phong kiếm tiền -> Chạm Vào Ngay !</div>
                  </div>
               </div>
            </div>
         </div>

         <!-- khai thac vang -->
         <div  class="d-flex align-items-center flex-fill"  style="cursor: pointer;" (click)="onSystem('2')">
            <div class="d-flex align-items-center flex-fill card shadow-sm ml-1 mr-1 mt-1">
               <div class="card-body" style="padding: 2px;">
                  <div class="d-flex justify-content-center align-items-center p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Khai Thác Vàng Online</div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                     <div><img alt="img" src="../../../assets/image/khaithacvang_icon.png" style="width: 200px; height: 200px;" /></div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 10pt;">Kiếm tiền bá đạo -> Chạm Vào Ngay !</div>
                  </div>
               </div>
            </div>
         </div>

         <!-- bac nong dan -->
         <div  class="d-flex align-items-center flex-fill"  style="cursor: pointer;" (click)="onSystem('3')">
            <div class="d-flex align-items-center flex-fill card shadow-sm ml-1 mr-1 mt-1">
               <div class="card-body" style="padding: 2px;">
                  <div class="d-flex justify-content-center align-items-center p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Bác Nông Dân - Kiếm Tiền Online</div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                     <div><img alt="img" src="../../../assets/image/bacnongdan_icon.png" style="width: 200px; height: 200px;" /></div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center p-2">
                     <div style="color: chocolate; font-weight: bold; font-size: 10pt;">Làm giàu nhanh chóng -> Chạm Vào Ngay !</div>
                  </div>
               </div>
            </div>
         </div>

      </div>

   </div>


   <!-- footer -->
   <app-footer></app-footer>
   
</div>



