<!-- header -->
<div class="d-flex pt-2 pb-1 shadow">
   <div class="d-flex flex-grow-1 ml-2">
      <div class="ml-1">
         <div class="d-flex">
            <img alt="img" (click)="openLink('./')"
            src="../../../assets/image/saishin-dev.png" style="width: 56px; height: 56px; cursor: pointer;"/>
         </div>
         <div style="color:chocolate; font-weight: bold; font-size: 8pt; margin-top: 6px;">Saishin Dev</div>
      </div>
      <div class="ml-3 d-flex align-items-center flex-grow-1">
         <div class="d-flex flex-column flex-grow-1">

            <!-- contact social -->
            <app-contact-social></app-contact-social>
            
            <!-- system title -->
            <div class="flex-grow-1" style="font-weight:bold; font-size: 12pt; color:chocolate;">{{title}}</div>
   
            <!-- user info -->
            <div class="d-flex flex-row-reverse align-items-center flex-grow-1 mr-2" style="margin-bottom: 1px; padding: 0px;">
               
               <!-- xu -->
               <div class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                  <div class="d-flex align-items-center card shadow-sm" style="margin-top: 2px; background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                     <div class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                        <div>{{coin}}</div>
                     </div>
                  </div>
               </div>
               <div class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px;">
                  <img alt="img" (click)="openLink('https://zalo.me/g/xnniht526')" 
                  src="../../../assets/image/piggy-bank.png" class="ml-4 mr-2" style="width: 28px; height: 28px;"/>
               </div>
               <!-- user name -->
               <div class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                  <div class="d-flex align-items-center card shadow-sm" style="margin-top: 2px; background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                     <div class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                        <div>{{userName}}</div>
                     </div>
                  </div>
               </div>
               <div class="d-flex align-items-center justify-content-center" style="cursor: pointer; padding: 0px;">
                  <img alt="img" (click)="openLink('https://zalo.me/g/xnniht526')"
                  src="{{userPhotoUrl}}" class="ml-2 mr-2 rounded-circle" style="border: 1px; border-style: solid; border-color: chocolate; width: 25px; height: 25px;"/>
               </div>
            </div>

         </div>
      </div>
   </div>
</div>
