<!-- danh sách phương thức đổi thưởng title -->
<div class="d-flex align-items-center card shadow-sm ml-1 mr-1 mt-3" style="background-color: chocolate; color: white; font-weight: bold; font-size: 10pt; width: 195px;">
   <div class="card-body" style="padding: 2px;">
      <div>Người Dùng Và Hệ Thống</div>
   </div>
</div>

<!-- các thông tin cơ bản -->
<div class="d-flex flex-wrap mt-2">

   <!-- tài khoản -->
   <div class="d-flex flex-fill" style="width: 280px;">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/user_icon.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Tài Khoản: {{userName}}</div>
                     <div style="color: chocolate; font-weight: bold; font-size: 6pt;">Email: {{email}}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- trạng thái -->
   <div class="d-flex flex-fill" style="width: 280px;">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/user_status.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Trạng Thái: {{status}}</div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Ghi chú: {{warning}}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- mã mời của tôi -->
   <div class="d-flex flex-fill" style="width: 280px;">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/invite_user.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Mã Mời Bạn Bè:
                        </div>
                        <div class="ml-1" style="color: red; font-weight: bold; font-size: 12pt;">
                           {{myReferralKey}}
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Mẹo: Hãy dùng mã trên để mời bạn bè cùng cày Xu.</div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Người mời và người nhập cùng nhận được {{invateRefCoin}} Xu !</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- nhập mã giới thiệu -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onDeveloping()">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/input_code.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Nhập Mã Giới Thiệu Lấy Xu
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Mẹo: Bạn sẽ nhận được {{invateRefCoin}} Xu ngay lập tức </div>
                     <div class="d-flex align-items-center" style="color: red; font-weight: bold; font-size: 6pt;">
                        <div>
                           cho mỗi lần nhập mã thành công.
                        </div>

                        <div class="d-flex align-items-center justify-content-center ml-2" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                           <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                              <div class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                                 <div>Nhập Ngay</div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Nhập thông tin tk momo -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;"  (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/momo.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Nhập Thông Tin Ví Momo
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Mẹo: Hãy nhập đúng thông tin ví để nhận</div>
                     <div class="d-flex align-items-center" style="color: red; font-weight: bold; font-size: 6pt;">
                        <div>
                           tiền nhanh chóng.
                        </div>

                        <div class="d-flex align-items-center justify-content-center ml-2" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                           <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                              <div class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                                 <div>Nhập Ngay</div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Nhập thông tin tk zalopay -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/zalopay.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Nhập Thông Tin Ví Zalopay
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Mẹo: Hãy nhập đúng thông tin ví để nhận</div>
                     <div class="d-flex align-items-center" style="color: red; font-weight: bold; font-size: 6pt;">
                        <div>
                           tiền nhanh chóng.
                        </div>

                        <div class="d-flex align-items-center justify-content-center ml-2" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                           <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                              <div class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                                 <div>Nhập Ngay</div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Nhập thông tin tk nganhang -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/bank.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Nhập Thông Tin TK Ngân Hàng
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Mẹo: Hãy nhập đúng thông tin ví để nhận</div>
                     <div class="d-flex align-items-center" style="color: red; font-weight: bold; font-size: 6pt;">
                        <div>
                           tiền nhanh chóng.
                        </div>

                        <div class="d-flex align-items-center justify-content-center ml-2" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                           <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                              <div class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                                 <div>Nhập Ngay</div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- lịch sử đổi thưởng -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/reward.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Lịch Sử Đổi Thưởng
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Xem tất cả những lần đổi thưởng của bạn.</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- lịch sử cày xu -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onHistoryMakeCoin()">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/piggy-bank.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Lịch Sử Cày Xu
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Xem tất cả Xu bạn kiếm được trong hệ thống.</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Lịch Sử Xu sự kiện -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/event.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Lịch Sử Xu Sự Kiện
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Xem tất cả Xu bạn nhận được từ các Sự Kiện.</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- bảng xếp hạng hôm nay -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/top1.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Bảng Xếp Hạng Hôm Nay
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Xem bảng xếp hạng cày Xu trong ngày.</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- bảng xếp hạng cao thủ -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/winner.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Bảng Xếp Hạng Cao Thủ
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Xem bảng xếp hạng Cao Thủ toàn hệ thống.</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- tùy chỉnh ẩn hiện tài khoản -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/setting.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Tùy Chỉnh Ẩn Hiện Tài Khoản
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Bấm vào đây để ẩn tài khoản của bạn.</div>
                     <div class="d-flex align-items-center" style="color: red; font-weight: bold; font-size: 6pt;">
                        <div>
                           trong các bảng xếp hạng.
                        </div>

                        <div class="d-flex align-items-center justify-content-center ml-2" style="cursor: pointer; padding: 0px; color: chocolate; font-weight: bold; font-size: 8pt;">
                           <div class="d-flex align-items-center card shadow-sm ml-2" style="background-color: chocolate; color: white; font-weight: bold; font-size: 8pt;">
                              <div class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                                 <div>Ẩn</div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- điều khoản sử dụng -->
   <div class="d-flex flex-fill" style="width: 280px;">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/setting.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Điều Khoản Sử Dụng
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Sử dụng hệ thống để cày Xu và đầu tư phải tuân thủ</div>
                     <div class="d-flex align-items-center" style="color: red; font-weight: bold; font-size: 6pt;">
                        <div>
                           các qui định được ghi dưới cùng của trạng này.
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- about -->
   <div class="d-flex flex-fill" style="width: 280px;">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/about.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Về Chúng Tôi
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Làm Vườn Kiếm Tiền Online là hệ thống kiếm tiền</div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">online toàn diện và uy tín số 1 hiện nay do Saishin Dev có trụ sở tại Nhật Bản nghiên cứu và phát triển.</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- contact -->
   <div class="d-flex flex-fill" style="width: 280px;">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/contact-us.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center flex-fill ml-1 p-2">
                  <div class="flex-fill">
                     <div class="d-flex align-items-center" style="color: chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Thông Tin Liên Lạc
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Email: saishindev@gmail.com, admin@saishin.net</div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Adress: 〒460-0007 愛知県名古屋市中区新栄２丁目１−43-2</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
  
</div>
