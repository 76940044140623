import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EndgameDialogComponent } from './endgame-dialog/endgame-dialog.component';
import {TranslateService} from './translate.service';


@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.css']
})
export class TranslateComponent implements OnInit {

  title = 'Translate';
  furigana = '日本語';
  strText = new FormControl('', [
    this.textValidator()
  ]);


  // hàm tạo
  constructor(private translateService: TranslateService,
    private dialog: MatDialog) {
  }

  // định nghĩa sự kiện cho page
  // giá trị trả về phải luôn set vì trong tslint.json đang set là
  // "typedef": [
  //   true,
  //   "call-signature"
  // ],
  // set false thì ko cần ghi rõ giá trị trả về
  ngOnInit(): void {
    this.strText.setValue('');
  }

  showdialog() {
    const dialogRef = this.dialog.open(EndgameDialogComponent);
    dialogRef.afterClosed().subscribe(res => {
      alert('dd');
    });
  }

  translate(): void {
    // get furigana
    // this.translateService.getTranslate('translate/furigana').subscribe(res => {
    //  this.furigana = res.furigana;
    // }, error => {
    //   throw error;
    // });

    // const body = {
    //   text: this.strText
    // };

    // this.translateService.getTranslateWithText('translate/furiganaWithText', body).subscribe(res => {
    //   this.furigana = res.furigana;
    //  }, error => {
    //    throw error;
    //  });


    // this.translateService.connectdb('translate/seikyu').subscribe(res => {
    //   this.furigana = res.furigana;
    //  }, error => {
    //    throw error;
    //  });

    const body = {
      text: this.strText
    };

    this.translateService.getGoogleTranslateWithText('googleTranslate/translate', body).subscribe(res => {
      this.furigana = '(' + res.didYouMean + ') ' +  res.translatedText;
     }, error => {
       throw error;
     });



  }


  textValidator(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      // エラーメッセージ
      let forbidden: boolean;
      const lengthMin = 0;
      const lengthMax = 4000;
      // チェック結果格納
      if (control.value === '') {
        // 桁数エラー
        forbidden = true;
      }else {
        forbidden = false;
      }
      return forbidden ? { strText: { value: control.value } } : null;
    };
  }

}
