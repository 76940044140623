import { Injectable } from '@angular/core';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})
export class CommonLogicService {

  constructor(public commonService: CommonService) {
  }

  // get user info
  getUserInfo(system: string, userId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        system: system,
        userId: userId
      };
      this.commonService.exeApi('system/user/userInfo', body).subscribe(kekkaInfo => {
        resolve(kekkaInfo);
       }, error => {
          reject(error);
       });
    });
  }

  checkAd(adTagList: Array<HTMLElement | any>): boolean {
    for (const adTag of adTagList) {
      const admax = adTag?.getElementsByClassName('admax-switch');
      if (admax.length > 0) {
        const frame = admax[0].getElementsByTagName('iframe');
        if (frame.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  checkPopUpAd(): boolean {
    const adTag = document.getElementById('saishin-script-pop-ad');
    if (adTag !== undefined && adTag !== null) {
      return true;
    }
    return false;
  }




}
