<div class="container-fluid" style="padding: 0px; height: 100vh;">

   <!-- header -->
   <app-system-header [system]="system" [coin]="coin"></app-system-header>

   <!-- task list -->
   <app-task-list *ngIf="kino === 'system'" [system]="system" (openTaskEvent)="onOpenTask($event)" ></app-task-list>

   <!-- reward exchange list -->
   <app-reward-exchange *ngIf="kino === 'system'" (openRewardExchangeMethodEvent)="onOpenRewardExchangeMethod($event)"></app-reward-exchange>

   <!-- system info -->
   <app-system-info *ngIf="kino === 'system'"
      [system]="system" [email]="email" [userName]="userName" [userId]="userId"
      (onHistoryMakeCoinEvent)="systemNavigation($event)">
   
   </app-system-info>

   <!-- history cày xu -->
   <app-history-make-coin *ngIf="kino === 'history-make-coin'" [system]="system" [userId]="userId"
      (onBackSystemEvent)="systemNavigation($event)">
   </app-history-make-coin>

   <!-- footer -->
   <app-footer></app-footer>

</div>