import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as uuid from 'uuid';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public baseurl = 'https://api.saishin.net/saishinDevWebApi/';
  // public baseurl = 'http://localhost:3002/saishinDevWebApi/';

  constructor(public httpClient: HttpClient) {
  }

  public createHttpHeaders = (): HttpHeaders => {
    const result = new HttpHeaders({
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
      expires: '-1',
      'x-browser-id': uuid.v4(),
      'x-action-id': uuid.v4(),
      'x-request-id': uuid.v4(),
      'Content-Type': 'application/json',
       Authorization: 'Bearer my-token',
      'My-Custom-Header': 'Saishin Web Game'
    });
    return result;
  }

  // post
  public exeApi(apiUrl: string, body: any): Observable<any> {
    const optionHeader = this.createHttpHeaders();
    // lưu ý body là một json tùy ý
    return this.httpClient.post<any>(this.baseurl + apiUrl, body, {headers: optionHeader})
    .pipe(
      map((json: any[]) => {
        return json;
      }), catchError(
        json => throwError(json)
        )
    );
  }

}
