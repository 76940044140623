import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-contact-social',
  templateUrl: './contact-social.component.html',
  styleUrls: ['./contact-social.component.css']
})
export class ContactSocialComponent implements OnInit {
 
  constructor() {
  }

  async ngOnInit(): Promise<void> {
  }




  openLink(link: string) {
    window.document.location.href = link;
  }

  showAlert(message: string) {
    alert(message);
  }

}
