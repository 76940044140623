import {Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-translate-view',
  templateUrl: './translate-view.component.html',
  styleUrls: ['./translate-view.component.css']
})
export class TranslateViewComponent implements OnInit {

  title = 'Japanese Translate';


  // hàm tạo
  constructor(
  ) {
  }

  // định nghĩa sự kiện cho page
  // giá trị trả về phải luôn set vì trong tslint.json đang set là
  // "typedef": [
  //   true,
  //   "call-signature"
  // ],
  // set false thì ko cần ghi rõ giá trị trả về
  ngOnInit(): void {
  }

}
