<div class="d-flex justify-content-center align-items-center" style="height: 252px;">
    <!-- popad1 -->
    <div class="d-flex justify-content-center">
       <app-show-ad id="adEl1" [adId]="ad1"></app-show-ad>
    </div>
    <div style="position: absolute ; top:0px; right: 0px;" >
       <img alt="img" (click)="onCloseAd()" src="../../../assets/image/close.png" style="width: 20px; height: 20px;"/>
    </div>
 </div>
 <div class="d-flex justify-content-center align-items-center" style="height: 252px;">
    <!-- poad2 -->
    <div class="d-flex justify-content-center">
       <app-show-ad id="adEl2" [adId]="ad2"></app-show-ad>
    </div>
 </div>
 <div class="d-flex justify-content-center align-items-center p-5 text-color-main" style="font-weight:bold; font-size: 30pt;">
    <p>{{gameKekka}}</p>
 </div>
