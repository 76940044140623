<div class="container-fluid" style="padding: 0px;">

   <!-- header info game -->
   <div class="d-flex" style="background-color: #C9F7C9; height: 40px;">
      <div class="d-flex ml-2">
         <div class="d-flex align-items-center text-color-main" style="font-weight:bold;">
            <div>{{gameTitle}}</div>
         </div>
         <div class="ml-3 d-flex align-items-center">
            <img alt="img" src="../../../assets/image/trongcay.png" style="width: 25px; height: 25px;"/>
         </div>
      </div>

      <div class="d-flex ml-auto mr-2">

         <!-- xu -->
         <div class="d-flex align-items-center justify-content-center" style="padding: 0px;">
            <img alt="img"         
            src="../../../assets/image/pigy_saving.png" class="ml-4 mr-2" style="width: 28px; height: 28px;"/>
         </div>
         <div class="d-flex align-items-center justify-content-center mr-3" style="padding: 0px; color: #228B22; font-weight: bold; font-size: 8pt;">
            <div class="d-flex align-items-center card shadow-sm" style="margin-top: 2px; background-color: #228B22; color: white; font-weight: bold; font-size: 8pt;">
               <div class="card-body" style="padding-left: 6px; padding-right: 6px; padding-top: 0px; padding-bottom: 0px;">
                  <div>{{coin}}</div>
               </div>
            </div>
         </div>

         <!-- câu hỏi -->
         <div class="ml-3 d-flex align-items-center">
            <img alt="img" src="../../../assets/image/question.png" style="width: 25px; height: 25px;"/>
         </div>
         <div class="ml-1 d-flex align-items-center text-color-main" style="font-weight:bold;">
            <div>{{ playedCount + ' / ' + imageCouple }}</div>
         </div>
      </div>
   </div>

   <!-- hướng dẫn game -->
   <div *ngIf="isStartGame"
      class="d-flex justify-content-center align-items-center card shadow-sm m-2 text-color-main" style="font-weight: bold; font-size: 15pt;">
      <div class="card-body pt-3 pb-3">
         <div style="text-align: center;">Chọn ra từng cặp cây giống nhau cho đến hết !</div>
      </div>
   </div>

   <!-- fix ads -->
   <app-show-ad [adId]="fixAd"></app-show-ad>

   <!-- bắt đầu game -->
   <div *ngIf="isStartGame" 
      class="d-flex justify-content-center" style="cursor: pointer;">
      <div class="d-flex align-items-center card shadow-sm"
         (click)="onStartGame()">
         <div class="d-flex align-items-center card shadow-sm bg-color-main" style="margin: 4px; color: white; font-weight: bold; 
            font-size: 15pt;">
            <div class="card-body pl-2 pr-2 pt-1 pb-1">Bắt Đầu</div>
         </div>
      </div>
   </div>

   <!-- nội dung game -->
   <div *ngIf="isPlaying">
      <div 
         *ngFor="let recordImageList of showPlaySelectImageList; index as rIndex" 
         class="d-flex justify-content-center mt-1">

         <div *ngFor="let imageInfo of recordImageList; index as iIndex" 
            class="d-flex">

            <div *ngIf="imageInfo.show"
               class="card" style="margin: 1px;"
               (click)="onImageClick(imageInfo.playId, rIndex, iIndex)">
               <div class="card-body pl-1 pr-1 pt-1 pb-1">
                  <img alt="img" src="{{imageInfo.src}}" style="width: 47px; height: 47px; cursor: pointer;" />
               </div>
            </div>
            
         </div>

      </div>
   </div>

   <!-- fix ads -->
   <app-show-ad [adId]="fixAd"></app-show-ad>
      
</div>



