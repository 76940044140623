<div class="row">
    <div class="col-12 text-center">
        <textarea matInput maxlength="5000" [(ngModel)]="strText" style="width: 90%;" rows="20"></textarea>
    </div>
</div>

<div class="row">
    <div class="col-12 text-center">
        <button mat-button (click)="showdialog()">show dialog</button>
    </div>
</div>
<br>
<div class="row">
    <div class="col-12 text-center">
        <div [innerHTML]="furigana" style="width: 90%; margin-left: 5%;"></div>
    </div>
</div>
