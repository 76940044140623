import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from 'angularx-social-login';
import * as uuid from 'uuid';

export const uniqueId = '202111130338582f134e4e457f4f5f99720edb18e9005e_saishindev';
export const uniqueUUID = uniqueId + '_uuid';

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); 
}

export function setCookie(cName: string, cValue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cName + '=' + cValue + ';' + expires + ';path=/';
}

export function getCookie(cName: string): string {
  let name = cName + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookieList = decodedCookie.split(';');
  for(const cStr of cookieList) {
    if (cStr.includes(name)) {
      return cStr.replace(name, '');
    }
  }
  return '';
}

export function setUUID(userId: string) {
  setCookie(uniqueUUID, userId, 1000);
}

export function getUUID(): string {
  let UUID = getCookie(uniqueUUID);
  if ((UUID || '') === '') {
    UUID = uuid.v4();
    // tạo user Id và lưu trên client 1000 ngày
    setUUID(UUID);
  }
  return UUID;
}

export async function getSocialUser(socialAuthService: SocialAuthService): Promise<SocialUser | any> {
  const socialUser: SocialUser | any = await new Promise((resove, reject) => {
    try {
      socialAuthService.authState.subscribe((user) => {
        resove(user);
      });
    } catch (err) {
      resove(null);
    }
  });
  return socialUser;
}

export async function logOut(socialAuthService: SocialAuthService): Promise<void> {
  await socialAuthService.signOut();
}

export function getSystemName(system: string): string {
  if (system === '1') {
    return 'Làm Vườn';
  } else if (system === '2') {
    return 'Khai Thác Vàng';
  } else if (system === '3') {
    return 'Bác Nông Dân';
  } else if (system === '5') {
    return 'Coin84';
  } else {
    return 'Làm Vườn';
  }
}


