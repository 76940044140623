import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateViewComponent } from './view/translate-view.component';
import { TranslateComponent } from './component/translate.component';

import { HomeViewComponent } from './view/home/home-view.component';
import { HomeComponent } from './component/home/home.component';

import { LoginViewComponent } from './view/login/login-view.component';
import { LoginGoogleComponent } from './component/login-google/login-google.component';

import { SystemViewComponent } from './view/system/system-view.component';
import { SystemComponent } from './component/system/system.component';

import { SelectImageViewComponent } from './view/select-image/select-image-view.component';
import { SelectImageComponent } from './component/select-image/select-image.component';
import { EndgameDialogComponent } from './component/endgame-dialog/endgame-dialog.component';
import { EndgamePageComponent } from './view/endgame-page/endgame-page.component';

import { ShowAdComponent } from './component/show-ad/show-ad.component';
import { LoadingSpinnerComponent } from './component/loading-spinner/loading-spinner.component';

import { TaskListComponent } from './component/task-list/task-list.component';
import { RewardExchangeComponent } from './component/reward-exchange/reward-exchange.component';
import { SystemInfoComponent } from './component/system-info/system-info.component';
import { ContactSocialComponent } from './component/contact-social/contact-social.component';
import { HistoryMakeCoinComponent } from './component/history-make-coin/history-make-coin.component';







import { SystemHeaderComponent } from './component/system-header/system-header.component';
import { FooterComponent } from './component/footer/footer.component';




import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider
} from 'angularx-social-login';



@NgModule({
  declarations: [
    AppComponent,
    TranslateViewComponent,
    TranslateComponent,
    
    HomeViewComponent,
    HomeComponent,

    LoginViewComponent,
    LoginGoogleComponent,

    SystemViewComponent,
    SystemComponent,
    ContactSocialComponent,

    SelectImageViewComponent,
    SelectImageComponent,
    EndgameDialogComponent,
    ShowAdComponent,
    EndgamePageComponent,
    LoadingSpinnerComponent,
    SystemHeaderComponent,
    FooterComponent,
    TaskListComponent,
    RewardExchangeComponent,
    SystemInfoComponent,
    HistoryMakeCoinComponent






  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatGridListModule,
    MatGridListModule,
    MatSnackBarModule,

    SocialLoginModule
  ],
  entryComponents: [EndgameDialogComponent],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('1018743692580-lts9iv6813budpmf5ctjevicsnj75hk8.apps.googleusercontent.com',
            { scope: 'email', plugin_name: 'popup_closed_by_user'})
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }


// run (saishin.net saishin): 1018743692580-lts9iv6813budpmf5ctjevicsnj75hk8.apps.googleusercontent.com 
// run (game.saishin.net saishindev): 1018743692580-k3jtoli0o4hiaaef9q9m4qgvqshcfsbk.apps.googleusercontent.com       
// debug: 1018743692580-86p3gf9pq8ct4g0mbr2284dkops90a55.apps.googleusercontent.com
