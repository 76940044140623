import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { getSocialUser, getSystemName } from '../../service/common/common-function.service';

import {
  SocialAuthService,
  SocialUser,
} from 'angularx-social-login';

@Component({
  selector: 'app-system-header',
  templateUrl: './system-header.component.html',
  styleUrls: ['./system-header.component.css']
})
export class SystemHeaderComponent implements OnInit {

  @Input() system: string = '';
  @Input() coin: number = 0;

  systemName: string = '';
  socialUser: SocialUser | any;
  userName: string = '';
  userPhotoUrl: string = '../../../assets/image/user_icon.png';
  title: string = '';

  constructor(
    private router: Router,
    private socialAuthService: SocialAuthService) {
  }

  async ngOnInit(): Promise<void> {

    // phán định hệ thống
    this.systemName = getSystemName(this.system);
    this.title = this.systemName + ' Kiếm Tiền Online';

    // nếu đã login và còn lưu giữ tại client
    this.socialUser = await getSocialUser(this.socialAuthService);

    // nếu không tồn tại user login google thì cho ra màn hình login trở lại
    if (!this.socialUser) {
      this.router.navigate(['login', this.system, '']);
    } else {
      this.userName = ((this.socialUser?.name || '') as string).slice(-10);
      this.userName = this.userName.trim();
      this.userPhotoUrl = this.socialUser?.photoUrl;
    }

  }

  onSystem(system: string) {
    this.router.navigate(['login', system, '']);
  }

  showAlert(message: string) {
    alert(message);
  }
  
  openLink(link: string) {
    window.document.location.href = link;
  }

  menuBarClick() {
    alert('Đang phát triển, hãy quay lại sau !');
  }




  

  


}
