import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/service/common/common.service';


@Injectable({
  providedIn: 'root'
})
export class SelectImageService {

  constructor() {
  }
  
}
