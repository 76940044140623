import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as uuid from 'uuid';


@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private baseurl = 'http://localhost:3001/';

  constructor(private httpClient: HttpClient) {
  }

  // get
  getTranslate(type: string): Observable<any> {
    const optionHeader = this.createHttpHeaders();
    return this.httpClient.get<any>(this.baseurl + type, {headers: optionHeader, params: {}})
    .pipe(
      map((json: any[]) => {
        return json;
      }), catchError(
        json => throwError(json)
        )
    );
  }

    // post
    getTranslateWithText(type: string, body: any): Observable<any> {
      const optionHeader = this.createHttpHeaders();
      // lưu ý body là một json tùy ý
      return this.httpClient.post<any>(this.baseurl + type, body, {headers: optionHeader})
      .pipe(
        map((json: any[]) => {
          return json;
        }), catchError(
          json => throwError(json)
          )
      );
    }

 // post
 getGoogleTranslateWithText(type: string, body: any): Observable<any> {
   const optionHeader = this.createHttpHeaders();
   // lưu ý body là một json tùy ý
   return this.httpClient.post<any>(this.baseurl + type, body, {headers: optionHeader})
   .pipe(
     map((json: any[]) => {
       return json;
     }), catchError(
       json => throwError(json)
       )
   );
 }

  private createHttpHeaders = (): HttpHeaders => {
    const result = new HttpHeaders({
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
      expires: '-1',
      'x-browser-id': uuid.v4(),
      'x-action-id': uuid.v4(),
      'x-request-id': uuid.v4(),
      'Content-Type': 'application/json',
       Authorization: 'Bearer my-token',
      'My-Custom-Header': 'nihonnet'
    });
    return result;
  }





}
