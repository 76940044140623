import {Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EndgameDialogComponent } from 'src/app/component/endgame-dialog/endgame-dialog.component';

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.css']
})
export class HomeViewComponent implements OnInit {

  // hàm tạo
  constructor(){
  }

  ngOnInit() {
  }

}
