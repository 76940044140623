import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateViewComponent } from './view/translate-view.component';
import { SelectImageViewComponent } from './view/select-image/select-image-view.component';
import { EndgamePageComponent } from './view/endgame-page/endgame-page.component';
import { LoginViewComponent } from './view/login/login-view.component';
import { HomeViewComponent } from './view/home/home-view.component';
import { SystemViewComponent } from './view/system/system-view.component';

const routes: Routes = [
  
 

  { path: '', component: HomeViewComponent },
  { path: 'home', component: HomeViewComponent },
  { path: 'login/:system/:redirect', component: LoginViewComponent },
  { path: 'system/:system/:userId', component: SystemViewComponent },


  { path: 'selectImage/:userId/:taskId', component: SelectImageViewComponent },
  { path: 'endgame/:ad1/:ad2/:gameKekka/:userId/:gameId/:system/:gameName', component: EndgamePageComponent },

  // khi không tồn tại đường dẫn hoặc param truyền sai, sẽ về trang home
  // lưu ý, dòng này phải để nằm dưới cùng
  { path: '**', component: HomeViewComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
