import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment = require('moment');
import { CommonService } from 'src/app/service/common/common.service';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isHome: boolean = true;
  isHotNews: boolean = false;
  isEvent: boolean = false;
  isMakeMonneyList: boolean = false;
  settingInfo: any = null;

  // vì đang ở trang home nên sẽ dùng setting tổng quát tại dbkanri
  system: string = '4';
  eventDate: string = '';
  eventHotList: Array<string> = [];

  copyWright: string = moment(new Date()).format('YYYY');

  @ViewChild('eventHotDiv', {static: true}) eventHotDiv: ElementRef | any;

  constructor(
    private homeService: HomeService,
    private commonService: CommonService,
    private router: Router,
    private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {

    // lấy thông tin ngày event là hôm nay
    this.eventDate = moment(new Date()).format('YYYY/MM/DD');

    // chạy bất đồng bộ, không cần chờ để chạy
    this.getSettingInfo().then((settingInfo) => {
      this.eventHotList = ((settingInfo.eventHot || '') as string).split(';');
    });

  }

  getSettingInfo(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        system: this.system
      };
      this.commonService.exeApi('system/setting', body).subscribe(kekkaInfo => {
        resolve(kekkaInfo);
       }, error => {
          reject(error);
       });
    });
  }


  onSystem(system: string) {
    this.router.navigate(['login', system, '']);
  }

  showAlert(message: string) {
    alert(message);
  }
  
  openLink(link: string) {
    window.document.location.href = link;
  }

  menuBarClick() {
    alert('Đang phát triển, hãy quay lại sau !');
  }



}
