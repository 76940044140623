import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import moment = require('moment');

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
 
  copyWright: string = moment(new Date()).format('YYYY');

  constructor() {
  }

  async ngOnInit(): Promise<void> {
  }

}
