<!-- danh sách nhiệm vụ title -->
<div class="d-flex align-items-center card shadow-sm ml-1 mr-1 mt-3" style="background-color: chocolate; color: white; font-weight: bold; font-size: 10pt; width: 195px;">
   <div class="card-body" style="padding: 2px;">
      <div>Làm Nhiệm Vụ Kiếm Tiền</div>
   </div>
</div>

<!-- list nhiệm vụ từ server -->
<div class="d-flex flex-wrap align-items-center mt-2">

      <div *ngFor="let task of taskList; index as taskIndex" class="d-flex align-items-center flex-fill">
         <div *ngIf="task.isShowTask" class="d-flex align-items-center flex-fill" style="cursor: pointer;" (click)="onOpenTask(task)">
            <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
               <div class="card-body" style="padding: 2px;">
                  <div class="d-flex">
                     <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                        <img alt="img" src="{{task.iconTask}}" style="width: 40px; height: 40px;" />
                     </div>
                     <div class="d-flex align-items-center ml-1 p-2">
                        <div>
                           <div style="color: chocolate; font-weight: bold; font-size: 12pt;">{{task.taskName}}</div>
                           <div style="color: red; font-weight: bold; font-size: 6pt;">{{task.note}}</div>
                        </div>
                     </div>
                     <div class="d-flex align-items-center ml-1 mr-2">
                        <img alt="img" src="../../../assets/image/fire.png" style="width: 24px; height: 24px;" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

</div>

<!-- danh sách đầu tư title -->
<div class="d-flex align-items-center card shadow-sm ml-1 mr-1 mt-3" style="background-color: chocolate; color: white; font-weight: bold; font-size: 10pt; width: 195px;">
   <div class="card-body" style="padding: 2px;">
      <div>Đầu Tư Online Khôn Ngoan</div>
   </div>
</div>

<div class="d-flex flex-wrap align-items-center mt-2">
   <!-- mua bán xu -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onBuySellCoin()">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/buy-sell_coin.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div class="d-flex align-items-center" style="color:chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Mua Bán Xu Online
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Nền tảng mua bán Xu an toàn & miễn phí !</div>
                  </div>
               </div>
               <div class="d-flex align-items-center ml-1">
                  <img alt="img" src="../../../assets/image/fire.png" style="width: 24px; height: 24px;" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- đầu tư usdt -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onBuySellUsdt()">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/tether.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div class="d-flex align-items-center" style="color:chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Đầu Tư USDT
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Cực phẩm đầu tư tích lũy lợi nhuận cực cao !</div>
                  </div>
               </div>
               <div class="d-flex align-items-center ml-1">
                  <img alt="img" src="../../../assets/image/fire.png" style="width: 24px; height: 24px;" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- chẳn lẻ 1p -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onPlayChanle()">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/dice.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div class="d-flex align-items-center" style="color:chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Dự Đoán Chẳn Lẻ
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Tỉ lệ thưởng 1:1 cao nhất hành tinh !</div>
                  </div>
               </div>
               <div class="d-flex align-items-center ml-1">
                  <img alt="img" src="../../../assets/image/fire.png" style="width: 24px; height: 24px;" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- loto -->
   <div class="d-flex flex-fill" style="cursor: pointer; width: 280px;" (click)="onPlayLoto()">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body d-flex align-items-center flex-fill" style="padding: 2px;">
            <div class="d-flex align-items-center flex-fill">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/bingo.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div class="d-flex align-items-center" style="color:chocolate; font-weight: bold; font-size: 12pt;">
                        <div>
                           Dự Đoán Loto
                        </div>
                     </div>
                     <div style="color: red; font-weight: bold; font-size: 6pt;">Tỉ lệ thưởng 1:100 cao nhất hành tinh !</div>
                  </div>
               </div>
               <div class="d-flex align-items-center ml-1">
                  <img alt="img" src="../../../assets/image/fire.png" style="width: 24px; height: 24px;" />
               </div>
            </div>
         </div>
      </div>
   </div>

</div>

