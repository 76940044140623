import {Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EndgameDialogComponent } from 'src/app/component/endgame-dialog/endgame-dialog.component';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css']
})
export class LoginViewComponent implements OnInit {

  system: string = '';
  redirect: string = '';

  // hàm tạo
  constructor(public dialog: MatDialog,
    private route: ActivatedRoute){
      //system cho biết sẽ login vào hệ thống nào
      this.system = this.route.snapshot?.params?.system || '1';
      // redirect cho biết sẽ di chuyển đến màn hình nào (component nào), nếu ko chỉ định
      // thì login vào system đã login
      // lưu ý giá trị của redirect chính là tên của router cần di chuyển
      this.redirect = this.route.snapshot?.params?.redirect || '';
  }

  ngOnInit() {
  }

}
