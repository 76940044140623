import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import * as uuid from 'uuid';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { EndgameDialogComponent } from '../endgame-dialog/endgame-dialog.component';
import { Router } from '@angular/router';
import { LoginGoogleService } from './login-google.service';
import { getCookie, setCookie, uniqueId, getUUID, setUUID, getSocialUser, logOut, getSystemName } from '../../service/common/common-function.service';

import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from 'angularx-social-login';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { CommonService } from 'src/app/service/common/common.service';

@Component({
  selector: 'app-login-google',
  templateUrl: './login-google.component.html',
  styleUrls: ['./login-google.component.css']
})
export class LoginGoogleComponent implements OnInit {


  @Input() system: string = '';
  // giá trị này dùng để phán định sau khi login sẽ đi đâu.
  // nếu không set, sẽ mặc định đi vào hệ thống tuân theo biến system
  @Input() redirect: string = '';

  systemName: string = '';

  userId: string = '';

  socialUser: SocialUser | any;

  constructor(
    private loginGoogleService: LoginGoogleService,
    private commonService: CommonService,
    private loadingSpinnerService: LoadingSpinnerService,
    private router: Router,
    private dialog: MatDialog,
    private socialAuthService: SocialAuthService) {
  }

  async ngOnInit(): Promise<void> {

    // luôn get userid tại đây
    this.userId = getUUID();

    // phán định hệ thống
    this.systemName = getSystemName(this.system);

  }

  async loginWithGoogle(): Promise<void> {
    // chờ lấy giá thông tin login thành công
    this.socialUser = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    if (this.socialUser) {
      // hiển thị spinner process
      this.loadingSpinnerService.show();

      // thực hiện login vào hệ thống
      await this.accessSystem();

      // ẩn spinner process
      this.loadingSpinnerService.hide();

    }
  }

  async accessSystem(): Promise<void> {

    // check login phía server
    const loginKekka = await this.checkLogin();

    // login không hợp lệ
    if ((loginKekka.loginInfo || '') === '3') {
      // hiện thông báo lỗi
      alert('Hiện tại đã có một người khác ( Email: ' + (loginKekka.emailGoogle || '') + ' ) đã đăng nhập trên thiết bị này !!!');
    } else {
      // làm mới userId lấy từ server
      this.userId = loginKekka.userId;
      // chi trường hợp loginKekka.loginInfo = '2' là cần cập nhật lại userId lên browser nhưng để cho chắc ăn
      // cập nhật trong mọi trường họp đăng nhập thành công
      setUUID(this.userId);

      // di chuyển đến nơi phù hợp
      if (this.redirect === '') {
        this.router.navigate(['system', this.system, this.userId]);
      } else {
        // vì có chỉ định đăt biệt cần di chuyển, nên sẽ phán định theo yêu cầu sau tại đây
      }
    }

  }

  // hàm này gởi lên UUID lưu tại client (nếu ko có thì tạo mới), và email đã login lên server để check
  // tại server nếu tồn tại thì get thông tin, nếu ko tồn tại thì tạo user và trả về thông tin
  checkLogin(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const body = {
        userId: this.userId,
        emailGoogle: this.socialUser?.email,
        userName: this.socialUser?.name,
        system: this.system
      };
      this.commonService.exeApi('system/login/check/user', body).subscribe(kekkaInfo => {
        resolve(kekkaInfo);
       }, error => {
          reject(error);
       });
    });
  }




  




}
