import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-show-ad',
  templateUrl: './show-ad.component.html',
  styleUrls: ['./show-ad.component.css']
})
export class ShowAdComponent implements OnInit {

  @Input() adId: string = '';

  @ViewChild('adContainer', {static: true}) adContainer: ElementRef | any;
 
  constructor() {
  }

  async ngOnInit(): Promise<void> {

    // const divAd = document.createElement('div');
    // divAd.classList.add('admax-switch');
    // divAd.setAttribute('data-admax-id', this.adId);
    // divAd.setAttribute('style', 'display:inline-block;');
    // this.adContainer?.nativeElement.appendChild(divAd);

    // const tag1 = document.createElement('script');
    // tag1.type = 'text/javascript';
    // tag1.innerHTML = '(admaxads = window.admaxads || []).push({admax_id: "' + this.adId + '",type: "switch"});';
    // this.adContainer?.nativeElement.appendChild(tag1);

    // const tag2 = document.createElement('script');
    // tag2.type = 'text/javascript';
    // tag2.async = true;
    // tag2.src = 'https://adm.shinobi.jp/st/t.js';
    // this.adContainer?.nativeElement.appendChild(tag2);

  }

}
