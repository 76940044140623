<div class="container-fluid" style="padding: 0px;">

   <div class="d-flex flex-column justify-content-center align-items-center">
      <div style="position: relative;">
         <app-show-ad id="adEl1" [adId]="ad1"></app-show-ad>
   
         <div *ngIf="showCloseButton === '1'"  style="position: absolute ; top: 10px; left: 10px;" >
            <img alt="img" (click)="onCloseAd()" src="../../../assets/image/close.png" style="width: 20px; height: 20px; cursor: pointer;"/>
          </div>
          <div *ngIf="showCloseButton === '2'" style="position: absolute ; top: 10px; right: 10px;" >
             <img alt="img" (click)="onCloseAd()" src="../../../assets/image/close.png" style="width: 20px; height: 20px; cursor: pointer;"/>
          </div>
      </div>
   
      <div class="d-flex justify-content-center align-items-center text-color-main" style="font-weight:bold; font-size: 30pt;">
         {{gameInfo}}
      </div>
   
      <div style="position: relative;">
         <app-show-ad id="adEl2" [adId]="ad2"></app-show-ad>
         <div *ngIf="showCloseButton === '3'"  style="position: absolute ; top: 10px; left: 10px;" >
            <img alt="img" (click)="onCloseAd()" src="../../../assets/image/close.png" style="width: 20px; height: 20px; cursor: pointer;"/>
          </div>
          <div *ngIf="showCloseButton === '4'" style="position: absolute ; top: 10px; right: 10px;" >
             <img alt="img" (click)="onCloseAd()" src="../../../assets/image/close.png" style="width: 20px; height: 20px; cursor: pointer;"/>
          </div>
      </div>
   
      <div style="height: 200px;"></div>

   </div>

</div>




