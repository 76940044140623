import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonLogicService } from 'src/app/service/common/common-logic.service';

@Component({
  selector: 'app-system-info',
  templateUrl: './system-info.component.html',
  styleUrls: ['./system-info.component.css']
})
export class SystemInfoComponent implements OnInit {

  @Input() system: string = '';
  @Input() email: string = '';
  @Input() userName: string = '';
  @Input() userId: string = '';

  @Output() onHistoryMakeCoinEvent: EventEmitter<string> = new EventEmitter<string>();

  status: string = 'Bình Thường';
  warning: string = 'không có bất thường';

  taskList: Array<any> = [];
  invateRefCoin: number = 10;
  myReferralKey: string = '';

  constructor(
    private commonLogicService: CommonLogicService
  ) {
  }

  async ngOnInit(): Promise<void> {

    // lấy thông tin user (số Xu) từ server tại đây
    const userInfo = await this.commonLogicService.getUserInfo(this.system, this.userId);

    // phán định trạng thái tài khoản
    if (['1', '2', '3'].includes((userInfo?.status || '')) ) {
      this.status = 'Vi Phạm';
      this.warning = 'Vi Phạm Hệ Thống';
    }

    // mã mời
    this.myReferralKey = userInfo?.myReferralKey ;

  }

  onHistoryMakeCoin() {
    this.onHistoryMakeCoinEvent.emit('history-make-coin');
  }

  onDeveloping() {
    alert('Đang phát triển - Vui lòng quay lại sau !!!');
  }


  onRewardExchangeMethod(method: string, cardType: string) {
    alert('Đang phát triển - Vui lòng quay lại sau !!!');
  }




  

  


}
