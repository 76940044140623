import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import * as uuid from 'uuid';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { EndgameDialogComponent } from '../endgame-dialog/endgame-dialog.component';
import { Router } from '@angular/router';
import { getCookie, setCookie, uniqueId, getUUID, setUUID, getSocialUser, getSystemName } from '../../service/common/common-function.service';

import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from 'angularx-social-login';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { SystemService } from './system.service';
import { CommonService } from 'src/app/service/common/common.service';
import { CommonLogicService } from 'src/app/service/common/common-logic.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.css']
})
export class SystemComponent implements OnInit {

  @Input() system: string = '';
  @Input() userId: string = '';

  systemName: string = '';
  socialUser: SocialUser | any;
  userName: string = '';
  email: string = '';
  userPhotoUrl: string = '';

  title: string = '';

  taskList: Array<any> = [];

  coin: number = 0;

  userInfo: any;

  kino: string = 'system';

  constructor(
    private systemService: SystemService,
    private commonLogicService: CommonLogicService,
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService,
    private socialAuthService: SocialAuthService) {
  }

  async ngOnInit(): Promise<void> {

    // phán định hệ thống
    this.systemName = getSystemName(this.system);
    
    this.title = this.systemName + ' Kiếm Tiền Online';

    // hiển thị spinner process
    this.loadingSpinnerService.show();

    // nếu đã login và còn lưu giữ tại client
    this.socialUser = await getSocialUser(this.socialAuthService);

    // nếu không tồn tại user login google thì cho ra màn hình login trở lại
    if (!this.socialUser) {
      this.router.navigate(['login', this.system, '']);
    } else {
      this.userName = ((this.socialUser?.name || '') as string).slice(-10);
      this.userName = this.userName.trim();
      this.userPhotoUrl = this.socialUser?.photoUrl;
      this.email = this.socialUser.email;

      // lấy thông tin user (số Xu) từ server tại đây
      this.userInfo = await this.commonLogicService.getUserInfo(this.system, this.userId);
      if (this.userInfo.exist === '1') {
        this.coin = this.userInfo?.coin;
      } else {
        this.router.navigate(['login', this.system, '']);
      }
  
    }
    
    // ẩn spinner process
    this.loadingSpinnerService.hide();

  }

  // click vao task chơi game kiếm tiền
  onOpenTask(task: any) {
    if (task?.taskId === 'trongcay') {
      // di chuyển về màn hình chơi game trồng cay
      this.router.navigate(['selectImage', this.userId, task.taskId]);
    }
  }

  systemNavigation(event: string) {
    this.kino = event;
  }

  onOpenRewardExchangeMethod(rewardExchangeInfo: any) {
  }


  onSystem(system: string) {
    this.router.navigate(['login', system, '']);
  }

  showAlert(message: string) {
    alert(message);
  }
  
  openLink(link: string) {
    window.document.location.href = link;
  }

  menuBarClick() {
    alert('Đang phát triển, hãy quay lại sau !');
  }




  

  


}
