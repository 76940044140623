import {Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select-image-view',
  templateUrl: './select-image-view.component.html',
  styleUrls: ['./select-image-view.component.css']
})
export class SelectImageViewComponent implements OnInit {

  UUID: string = '';
  taskId: string = '';

  // hàm tạo
  constructor(public dialog: MatDialog,
    private route: ActivatedRoute){
      this.UUID = this.route.snapshot?.params?.userId || '';
      this.taskId = this.route.snapshot?.params?.taskId || '';
  }

  ngOnInit() {
  }

}
