<!-- danh sách phương thức đổi thưởng title -->
<div class="d-flex align-items-center card shadow-sm ml-1 mr-1 mt-3" style="background-color: chocolate; color: white; font-weight: bold; font-size: 10pt; width: 195px;">
   <div class="card-body" style="padding: 2px;">
      <div>Phương Thức Đổi Thưởng</div>
   </div>
</div>

<!-- list phương thức đổi thưởng -->
<div class="d-flex flex-wrap align-items-center mt-2">

   <!-- momo -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('momo', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" src="../../../assets/image/momo.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Thanh Toán Bằng Ví Momo</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- zalopay -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('zalopay', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/zalopay.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Thanh Toán Bằng Ví Zalopay</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- bank -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('bank', '')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/bank.png" style="width: 40px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Thanh Toán Bằng Ngân Hàng</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- viettel 20 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('vt', 'vt20')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/viettel20.webp" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Viettel 20K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- mobi 20 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('mb', 'mb20')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/mobi20.png" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Mobi 20K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- vina 20 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('vn', 'vn20')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/vina20.png" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Vina 20K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- viettel 10 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('vt', 'vt10')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/viettel10.png" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Viettel 10K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- mobi 10 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('mb', 'mb10')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/mobifone10.png" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Mobi 10K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- vina 10 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('vn', 'vn10')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/vinaphone10.png" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Vina 10K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- garena 100 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('grn', 'grn100')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/garena100.jpg" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Garena 100K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- garena 50 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('grn', 'grn50')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/garena50.jpg" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Garena 50K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- garena 20 -->
   <div class="d-flex align-items-center flex-fill" style="cursor: pointer; width: 280px;" (click)="onRewardExchangeMethod('grn', 'grn20')">
      <div class="d-flex flex-fill card shadow-sm ml-1 mr-1 mt-1">
         <div class="card-body" style="padding: 2px;">
            <div class="d-flex">
               <div class="d-flex align-items-center ml-2 mt-1 mb-1">
                  <img alt="img" class="rounded" src="../../../assets/image/garena20_2.png" style="width: 60px; height: 40px;" />
               </div>
               <div class="d-flex align-items-center ml-1 p-2">
                  <div>
                     <div style="color: chocolate; font-weight: bold; font-size: 12pt;">Đổi Card Garena 20K VND</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</div>
