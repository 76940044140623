import {Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-system-view',
  templateUrl: './system-view.component.html',
  styleUrls: ['./system-view.component.css']
})
export class SystemViewComponent implements OnInit {

  system: string = '';
  userId: string = '';

  // hàm tạo
  constructor(public dialog: MatDialog,
    private route: ActivatedRoute){
      //system cho biết sẽ login vào hệ thống nào
      this.system = this.route.snapshot?.params?.system || '1';
      this.userId = this.route.snapshot?.params?.userId || '1';
  }

  ngOnInit() {
  }

}
