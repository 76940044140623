import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-reward-exchange',
  templateUrl: './reward-exchange.component.html',
  styleUrls: ['./reward-exchange.component.css']
})
export class RewardExchangeComponent implements OnInit {

  @Input() system: string = '';
  @Output() openRewardExchangeMethodEvent: EventEmitter<any> = new EventEmitter<any>();

  taskList: Array<any> = [];

  constructor() {
  }

  async ngOnInit(): Promise<void> {
  }


  onRewardExchangeMethod(method: string, cardType: string) {
    alert('Đang phát triển, hãy quay lại sau !');
    this.openRewardExchangeMethodEvent.emit({ method: method, cardType: cardType });
  }




  

  


}
