import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'endgame-dialog',
  templateUrl: './endgame-dialog.component.html',
  styleUrls: ['./endgame-dialog.component.css']
})
export class EndgameDialogComponent {

  gameKekka: string = '';
  ad1: string = '';
  ad2: string = '';

  constructor(
    public dialogRef: MatDialogRef<EndgameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
      this.gameKekka = data.gameKekka;
      this.ad1 = data.ad1;
      this.ad2 = data.ad2;
  }

  onCloseAd() {
    this.dialogRef.close({
      close: '1'
    });
  }

}
