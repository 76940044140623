<div class="container-fluid login-background-image" style="padding: 0px; width: 100vw; height: 100vh;">

   <!-- Login Box -->
   <div class="d-flex justify-content-center align-items-center">
      <div class="card shadow-lg login-text-color" 
         style="margin-top: 100px; width: 300px; background-color: orange;">
         <div class="card-body pt-4 pb-4">
               <div style="text-align: center; font-weight: bold; font-size: 15pt;">Đăng Nhập Hệ Thống</div>
               <div style="text-align: center; font-weight: bold; font-size: 20pt;">{{systemName}}</div>
               
               <div class="d-flex justify-content-center align-items-center mt-3">
                  <div class="card shadow-sm login-text-color" style="width: 100px; height: 100px;">
                     <div class="d-flex justify-content-center align-items-center w-100 h-100">
                        <img alt="img" (click)="loginWithGoogle()" src="../../../assets/image/google_icon.png" style="width: 80px; height: 80px; cursor: pointer;"/>
                     </div>
                  </div>
               </div>

               <div class="mt-3" style="text-align: center; font-weight: bold; font-size: 10pt;">Đăng Nhập Bằng Gmail</div>

               <div class="mt-3" style="text-align: center; font-weight: bold; font-size: 6pt;">Đăng Nhập Là Bạn Đã Đồng Ý Với 
                  <a href="https://kasegu.saishin.net/privacy.html" style="color: blue; font-weight:bold;">
                     Điều Khoản Dịch Vụ
                  </a>
               </div>  
         </div>
      </div>
   </div>
   
      
</div>



